import axios from "axios";
import PaginationDTO from "@/models/PaginationDTO";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import TraderOperatorDTO from "@/models/TraderOperatorDTO";
import qs from "qs";

export const traderOperators = {
    list: async (pagination: PaginationDTO, req: any = {}): Promise<PaginatedResponseDTO<TraderOperatorDTO>> => {
        const resp = await axios({
            url: `/trader-operators`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TraderOperatorDTO>;
    },
    update: async (id: number, data: any): Promise<TraderOperatorDTO> => {
        const resp = await axios({
            url: `/trader-operators/${id}`,
            method: 'PUT',
            data: {
                ...data
            }
        });

        return resp.data as TraderOperatorDTO
    },
    create: async (data: any): Promise<TraderOperatorDTO> => {
        const resp = await axios({
            url: `/trader-operators`,
            method: 'POST',
            data: {
                ...data
            }
        });

        return resp.data as TraderOperatorDTO
    },
}