export enum DisputeResult {
    ACCEPTED = 'ACCEPTED',
    AMOUNT_CHANGED = 'AMOUNT_CHANGED',
    REQUISITES_MISMATCH_IN_CONFIRM_DOCS = 'REQUISITES_MISMATCH_IN_CONFIRM_DOCS',
    INVALID_PAYMENT_METHOD = 'INVALID_PAYMENT_METHOD',
    TTL_EXPIRED = 'TTL_EXPIRED',
    FRAUD_CONFIRM_DOCS = 'FRAUD_CONFIRM_DOCS',
    WAITING_CONFIRM_DOCS_FROM_MERCHANT = 'WAITING_CONFIRM_DOCS_FROM_MERCHANT',
    WAITING_CONFIRM_DOCS_FROM_TRADER = 'WAITING_CONFIRM_DOCS_FROM_TRADER',
    UNKNOWN = 'UNKNOWN',
}