import axios from "axios";
import ProviderDTO from "@/models/ProviderDTO";

export const providers = {
    all: async (): Promise<ProviderDTO[]> => {
        let resp = await axios({
            url: `/providers/all`,
            method: 'GET'
        });

        return resp.data as ProviderDTO[]
    },
    update: async (id: number, data: any): Promise<void> => {
        let resp = await axios({
            url: `/providers/${id}`,
            method: 'PUT',
            data: {
                ...data
            }
        });
    },
}