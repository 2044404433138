export enum OperationType {
    INVOICE_PAYMENT_ROLLBACK = 'invoice_payment_rollback',
    INVOICE_PAYMENT = 'invoice_payment',
    WITHDRAW = 'withdraw',
    WITHDRAW_FEE = 'withdraw_fee',
    TRANSFER = 'transfer',
    DEPOSIT = 'deposit',
    FREEZE = 'freeze',
    UNFREEZE = 'unfreeze',
    TRADE_PAYMENT = 'trade_payment',
    TRADE_PAYMENT_ROLLBACK = 'trade_payment_rollback',
    TRADE_REWARD = 'trade_reward',
    TRADE_REWARD_ROLLBACK = 'trade_reward_rollback',
    SERVICE_FEE = 'service_fee',
    SERVICE_FEE_ROLLBACK = 'service_fee_rollback',
    PARTNER_INCOME = 'partner_income'
}