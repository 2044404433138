import axios from "axios";
import CascadeDTO from "@/models/CascadeDTO";
import CascadeProviderDTO from "@/models/CascadeProviderDTO";

export const cascades = {
    all: async (): Promise<CascadeDTO[]> => {
        let resp = await axios({
            url: `/aggregator/cascades/all`,
            method: 'GET'
        });

        return resp.data as CascadeDTO[]
    },
    providers: async (id: number): Promise<CascadeProviderDTO[]> => {
        let resp = await axios({
            url: `/aggregator/cascades/${id}/providers`,
            method: 'GET'
        });

        return resp.data as CascadeProviderDTO[]
    },
    update: async (id: number, data: any): Promise<void> => {
        let resp = await axios({
            url: `/aggregator/cascades/${id}`,
            method: 'PUT',
            data: {
                ...data
            }
        });
    },
    create: async (data: any): Promise<void> => {
        let resp = await axios({
            url: `/aggregator/cascades`,
            method: 'POST',
            data: {
                ...data
            }
        });
    },
    updatePriorities: async (priorities: any): Promise<void> => {
        let resp = await axios({
            url: `/aggregator/cascades/priorities`,
            method: 'PUT',
            data: {
                ...priorities
            }
        });
    },
}