import axios from "axios";
import StatisticLabelOperationsDTO from "@/models/StatisticLabelOperationsDTO";
import StatisticLabelDTO from "@/models/StatisticLabelDTO";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import PaginationDTO from "@/models/PaginationDTO";

export const statistic = {
    labelsOperations: async (requestDTO: any = {}): Promise<StatisticLabelOperationsDTO[]> => {
        let resp = await axios({
            url: `/statistic/labels-operations`,
            data: {
                ...requestDTO
            },
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            method: 'POST',
        });

        return resp.data as StatisticLabelOperationsDTO[]
    },

    requisitesSummary: async (requestDTO: any = {}): Promise<any> => {
        let resp = await axios({
            url: `/statistic/requisites-summary`,
            data: {
                ...requestDTO
            },
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            method: 'POST',
        });

        return resp.data as any
    },

    traders: async (pagination: PaginationDTO, requestDTO: any = {}): Promise<PaginatedResponseDTO<any>> => {
        let resp = await axios({
            url: `/statistic/traders`,
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...requestDTO
            },
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            method: 'POST',
        });

        return resp.data as PaginatedResponseDTO<any>
    },

    labels: async (): Promise<StatisticLabelDTO[]> => {
        let resp = await axios({
            url: `/statistic/labels`,
            method: 'GET',
        });

        return resp.data as StatisticLabelDTO[]
    },
}